.connectid-verified-badge {
  display: flex;
  align-items: center;
  background-color: #E9E9E9;
  width: fit-content;
  border-radius: 50px;
}
.connectid-verified-badge--text {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  margin-top: 2.5px;
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
}
