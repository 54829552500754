.autorecharge-main {
  box-sizing: border-box;
  border: 1px solid #757575;
  border-radius: 3px;
  padding: 16px;
}
.autorecharge-main .info {
  margin-left: 2rem;
  margin-top: 15px;
}
.autorecharge-main.checkbox-halo {
  box-sizing: border-box;
  border: 2px solid #0064d2;
  border-radius: 3px;
}
.autorecharge-specialoffer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #0000001a;
  background: #FFF;
}
.autorecharge-specialoffer [class^="able-Lozenge"] {
  background-color: var(--accentPromotion);
}
.auto-title {
  margin-top: 50px;
}
@media screen and (min-width: 64rem) {
  .payment-auto-recharge [class*=able-MessageSection] {
    max-width: 100%;
  }
}
.payment-auto-recharge [class*=able-Checkbox] {
  width: 99%;
  margin-left: 0.5%;
}
.surcharge-close-btn {
  float: right;
}
.surchare-container {
  padding: 0px 24px;
  padding-bottom: 2rem !important;
}
#surchare-modal-container .modal-content {
  border-radius: 7px !important;
}
